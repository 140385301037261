.App {
  text-align: center;
}

.unfloat-labels .k-floating-label-container.k-empty > .k-label{
  top: 0px;
  left: 0px;
  pointer-events: all;  
}

.acs-button-userOpts-Opts {
  padding: 0px 10px;
  border-color: transparent;
  font-family: var(--bs-body-font-family);
  text-decoration: none;
  background: none;
  outline: none;
}

.acs-button-userOpts-Opts:link, hover, focus, active {
  outline: none;
  border-color: transparent;
  font-family: var(--bs-body-font-family);
  text-decoration: none;
  background: none;
}

.acs-button-userOpts-actions {
  padding: 0px 10px;
  border-width: 0 0 0 0;
  border-style: solid;
  border-color: inherit;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 0%;
    flex: 0 0 0%;
  -ms-flex-align:center;
      align-items: center;
  gap: 1px;
  overflow: hidden;
  clear: both;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
